<template>
  <div class="login-wrap">
    <div class="ms-login">
      <div class="login">
        <!-- <img class="img" src="@/assets/img/login/logo.png" /> -->
        <div class="text">
          <div class="name">爱析血透智能信息管理系统</div>
          <div class="pid">
            Hemadialysis Intelligent Information Mangenent System
          </div>
        </div>
      </div>
      <div class="ms-content">
        <div class="nav">
          <img src="@/assets/img/login/nav.png" />
        </div>
        <div class="form">
          <div class="form-top">
            <div class="come">welcome！</div>
            <div class="tip">立即登陆</div>
          </div>
          <el-form :model="param" :rules="rules" ref="login" label-width="0px">
            <el-form-item prop="username">
              <el-input v-model="param.username" placeholder="请输入用户名">
                <template #prefix>
                  <img class="form-img" src="@/assets/img/login/user.png" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input
                type="password"
                placeholder="请输入密码"
                v-model="param.password"
                @keyup.enter="submitForm()"
              >
                <template #prefix>
                  <img class="form-img" src="@/assets/img/login/password.png" />
                </template>
              </el-input>
            </el-form-item>
            <el-form-item>
              <el-checkbox
                v-model="checked1"
                label="记住密码"
                size="default"
              ></el-checkbox>
            </el-form-item>
            <el-form-item>
              <el-button
                :loading="loginLoading"
                type="primary"
                @click="submitForm()"
                >登录</el-button
              >
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, reactive } from 'vue'
import { useRouter } from 'vue-router'
import { ElMessage } from 'element-plus'
import service from '@/utils/request'
// import { useStore } from 'vuex'

export default {
  setup() {
    // const store = useStore()
    const loginLoading = ref(false)
    const router = useRouter()
    const param = reactive({
      username: '',
      password: '',
    })

    const rules = {
      username: [
        {
          required: true,
          message: '请输入用户名',
          trigger: 'blur',
        },
      ],
      password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
    }
    const login = ref(null)
    const submitForm = () => {
      login.value.validate(async (valid) => {
        if (valid) {
          loginLoading.value = true
          let res = await service.post('/api/login/index', param)
          loginLoading.value = false
          if (res.code === 0) {
            ElMessage.success(res.msg)
            localStorage.setItem('ms_token', res.data.userData.token)
            localStorage.setItem('ms_username', res.data.name)
            if (res.data.role) {
              localStorage.setItem('ms_role_type', res.data.role.role_type)
            }
            let userData = res.data
            userData.uid = res.data.id
            localStorage.setItem('ms_userData', JSON.stringify(userData))
            router.push('/')
            // store.dispatch('permission/generateRoutes').then(() => {
            //   router.push('/')
            // })
          }
        } else {
          // ElMessage.error('登录成功')
          return false
        }
      })
    }

    return {
      loginLoading,
      param,
      rules,
      login,
      submitForm,
    }
  },
}
</script>

<style scoped lang="scss">
.ms-login {
  &:deep(.el-form-item) {
    .el-form-item--large .el-form-item__content {
      line-height: 56px;
    }
    .el-input--large {
      line-height: 54px;
    }
    .el-input--large .el-input__inner {
      height: 56px;
      line-height: 56px;
      border-radius: 32px;
      padding-left: 55px;
      font-size: 14px;
    }
  }
  .el-button {
    height: 32px;
    background: #3166ae;
    border-radius: 16px;
    width: 100%;
    font-size: 13px;
    font-family: 'Source Han Sans CN-Medium';
  }
}
.login-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url(../assets/img/login/bg.png);
  background-size: 100%;
}
.ms-title {
  font-family: SourceHanSansCNBold;
  font-weight: bold;
  width: 100%;
  line-height: 50px;
  text-align: center;
  font-size: 20px;
  color: #fff;
  border-bottom: 1px solid #ddd;
}
.ms-login {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  width: 1000px;
  height: 600px;
  margin: auto;
  border-radius: 32px;
  background: #fff;
  overflow: hidden;
  .login {
    margin-top: 56px;
    margin-left: 80px;
    .img {
      width: 48px;
      height: 48px;
      float: left;
    }
    .text {
      // margin-left: 64px;
      .name {
        font-size: 24px;
        font-family: 'lianmengqiyilushuaizhengruiheiti-Regular';
        font-weight: 400;
        color: #3166ae;
        line-height: 24px;
        letter-spacing: 4px;
      }
      .pid {
        font-size: 14px;
        font-family: 'Source Han Sans SC-Medium';
        font-weight: 500;
        color: #3166ae;
        line-height: 14px;
        margin-top: 16px;
      }
    }
  }
}
.ms-content {
  display: flex;
  justify-content: space-around;
  margin-top: 48px;
  .nav {
    max-width: 562;
    img {
      width: 550px;
      margin-top: 20px;
      max-width: 100%;
    }
  }
  .form {
    width: 360px;
    margin-right: 20px;
    &:deep(.el-input__prefix) {
      left: 6px;
    }
    .form-img {
      height: 22px;
      margin-top: 5px;
    }
    .form-top {
      .come {
        font-size: 24px;
        font-family: 'Source Han Sans CN-Bold';
        font-weight: bold;
        color: #333333;
        line-height: 24px;
        margin-top: 40px;
      }
      .tip {
        font-size: 14px;
        font-family: 'Source Han Sans CN-Medium';
        font-weight: 500;
        color: #333333;
        margin-top: 17px;
        margin-bottom: 32px;
      }
    }
  }
}
</style>
